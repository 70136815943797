var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terms" },
    [
      _c("div", { staticClass: "legalMenu" }, [
        _c("ul", [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTerms(1)
                }
              }
            },
            [
              _c("a", { class: { primary: _vm.status == 1 } }, [
                _vm._v("이용 약관")
              ])
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.handleTerms(2)
                }
              }
            },
            [
              _c("a", { class: { primary: _vm.status == 2 } }, [
                _vm._v("개인정보 수집 및 이용 약관")
              ])
            ]
          )
        ])
      ]),
      _vm.status == 1
        ? _c("UseTerms")
        : _vm.status == 2
        ? _c("TermsPrivacy")
        : _c("TermsProvide")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }